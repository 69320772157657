import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './CitizenImpactSidebar.css';
import CitizenImpactDropdown from './CitizenImpactDropdown';

const CitizenImpactSidebar = ({ onGenderChange, onAgeRangeChange, onCategoryChange }) => {
  const [allData, setAllData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]); // State for category options
  const [genderOptions, setGenderOptions] = useState([]); // State for gender options
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/citizenimpact.json');
      const data = await response.json();
      setAllData(data);

      // Extract unique category options from the data
      const categories = [...new Set(data.map(item => item['Category']))].filter(c => c);
      // Add "MSME Impacted" as an additional option
      setCategoryOptions([...categories, 'MSME Impacted']);

      // Extract unique gender options from the data
      const genders = [...new Set(data.map(item => item['Gender']))].filter(g => g);
      setGenderOptions(genders);
    };
    fetchData();
  }, []);

  const handleGenderChange = (selectedGender) => {
    onGenderChange(selectedGender);
  };

  const handleAgeRangeChange = (selectedAgeRange) => {
    onAgeRangeChange(selectedAgeRange);
  };

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory === 'MSME Impacted') {
      // Navigate to MSME Impact page if MSME Impacted is selected
      navigate('/msme-impact');
    } else {
      // Handle category change for other selections
      onCategoryChange(selectedCategory);
    }
  };

  return (
    <div className="citizen-impact-sidebar">
      <h1 className="hd-impact-sidebar-header">Heat map based on</h1>
      
      <CitizenImpactDropdown
        label="Citizen Impacted"
        options={categoryOptions}
        onSelect={handleCategoryChange}
      />
      
      <h3 className="citizen-impact-sidebar-subheader">Filter Data By</h3>
      
      <nav>
        <ul>
          <li className="citizen-impact-dropdown">
            <CitizenImpactDropdown
              label="Select Gender"
              options={genderOptions}
              onSelect={handleGenderChange}
              header="Gender"
            />
          </li>
          <li className="citizen-impact-dropdown">
            <CitizenImpactDropdown
              label="Select Age Range"
              options={[...new Set(allData.map(item => item['Age Range']))]}
              onSelect={handleAgeRangeChange}
              header="Age Range"
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CitizenImpactSidebar;
