import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SchemeSidebar.css';
import Dropdown from './SchemeDropdown';

const SchemeSidebar = ({ setFilters, setHeatmapData }) => { 
  const navigate = useNavigate(); // Initialize the navigate function

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [schemes, setSchemes] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    serviceType: '',
    scheme: '',
    heatmapType: ''
  });

  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
        updateFilters(data);
        if (setHeatmapData) setHeatmapData(data); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const updateFilters = (data) => {
    const schemeNames = data.map(item => item.scheme_name);
    const serviceTypeNames = data.map(item => item["Type(Sch/Doc)"]);

    setSchemes([...new Set(schemeNames)]);
    setServiceTypes([...new Set(serviceTypeNames)]);
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: value
    }));
    setFilters(filterKey, value);

    let newFilteredData = data;

    if (filterKey === 'heatmapType') {
      newFilteredData = data.filter(item => {
        switch (value) {
          case 'Application Open':
            return item.OpenCount > 0;
          case 'Benefit Received':
            return parseInt(item.BV, 10) > 0;
          case 'Applications Submitted':
            return item.submitted > 0;
          case 'Benefits Unlocked':
            return item.BR > 0;
          default:
            return true;
        }
      });

      setFilteredData(newFilteredData);
      updateFilters(newFilteredData);
      if (setHeatmapData) setHeatmapData(newFilteredData);
    } else {
      newFilteredData = newFilteredData.filter(item => {
        switch (filterKey) {
          case 'serviceType':
            return item["Type(Sch/Doc)"] === value;
          case 'scheme':
            return item.scheme_name === value;
          default:
            return true;
        }
      });

      setFilteredData(newFilteredData);
      updateFilters(newFilteredData);
      if (setHeatmapData) setHeatmapData(newFilteredData);
    }
  };

  const handleClearFilter = (filterKey) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: ''
    }));
    setFilters(filterKey, '');

    const newFilteredData = data.filter(item => {
      return Object.keys(selectedFilters).every(key => {
        if (key === filterKey) return true;
        if (!selectedFilters[key]) return true;
        switch (key) {
          case 'serviceType':
            return item["Type(Sch/Doc)"] === selectedFilters[key];
          case 'scheme':
            return item.scheme_name === selectedFilters[key];
          default:
            return true;
        }
      });
    });

    setFilteredData(newFilteredData);
    updateFilters(newFilteredData);
    if (setHeatmapData) setHeatmapData(newFilteredData);
  };

  const filterOptions = {
    serviceType: serviceTypes,
    scheme: schemes
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <aside className="scheme-sidebar">
      <nav>
        <h2 className="scheme-sidebar-header">Filter Data By</h2>
        <ul className="scheme-sidebar-list">
          {Object.keys(selectedFilters).map((filterKey) => (
            filterKey !== 'heatmapType' && (
              <li className="scheme-sidebar-item" key={filterKey}>
                <div className="dropdown-container">
                  <Dropdown
                    label="Select"
                    options={filterOptions[filterKey] || []}
                    header={capitalizeFirstLetter(filterKey)}
                    selectedOption={selectedFilters[filterKey]}
                    onSelect={(value) => handleFilterChange(filterKey, value)}
                  />
                  
                </div>
              </li>
            )
          ))}
        </ul>
        <button 
          className="back-button" 
          onClick={() => navigate(-1)} // Navigate back to the previous page
        >
          Back To Home 
        </button>
      </nav>
    </aside>
  );
};

export default SchemeSidebar;
