import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './MsmeImpactSidebar.css'; // Update the path if necessary
import MsmeImpactDropdown from './MsmeImpactDropdown';

const MsmeImpactSidebar = ({ onGenderChange, onAgeRangeChange, onCategoryChange }) => {
  const [allData, setAllData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]); // State for category options
  const [genderOptions, setGenderOptions] = useState([]); // State for gender options
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/msmeimpact.json'); // Change this to your actual JSON file path
      const data = await response.json();
      setAllData(data);

      // Extract unique category options from the data
      const categories = [...new Set(data.map(item => item['Category']))].filter(c => c);
      // Add "Citizen Impact" as an additional option
      setCategoryOptions([...categories, 'Citizen Impacted']);

      // Extract unique gender options from the data
      const genders = [...new Set(data.map(item => item['Gender']))].filter(g => g);
      setGenderOptions(genders);
    };
    fetchData();
  }, []);

  const handleGenderChange = (selectedGender) => {
    onGenderChange(selectedGender);
  };

  const handleAgeRangeChange = (selectedAgeRange) => {
    onAgeRangeChange(selectedAgeRange);
  };

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory === 'Citizen Impacted') {
      // Navigate to the Citizen Impact page if "Citizen Impact" is selected
      navigate('/citizen-impact');
    } else {
      // Handle other category changes
      onCategoryChange(selectedCategory);
    }
  };

  return (
    <div className="msme-impact-sidebar">
      <h1 className="msme-impact-sidebar-header">Heat map based on</h1>
      
      <MsmeImpactDropdown
        label="MSMEs Impacted"
        options={categoryOptions}
        onSelect={handleCategoryChange}
      />
      
      <h3 className="msme-impact-sidebar-subheader">Filter Data By</h3>
      
      <nav>
        <ul>
          <li className="msme-impact-dropdown">
            <MsmeImpactDropdown
              label="Select Gender"
              options={genderOptions}
              onSelect={handleGenderChange}
              header="Gender"
            />
          </li>
          <li className="msme-impact-dropdown">
            <MsmeImpactDropdown
              label="Select Age Range"
              options={[...new Set(allData.map(item => item['Age Range']))]}
              onSelect={handleAgeRangeChange}
              header="Age Range"
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MsmeImpactSidebar;
