import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Google OAuth Provider
import './App.css';
import Header from './components/Header';
import Cards from './components/Cards';
import Dashboard from './components/Dashboard';
import Historical from './Historical Components/Historical';
import HDImpact from './HD Impact/HDImpactDashboard';
import CitizenImpact from './Citizen Impact/CitizenImpactDashboard';
import Map2 from './Map2/SchemeDashboard';
import ClientFilter from './ClientFilter/ClientDashboard';
import AllFilters from './AllFilters/AllFilterDashboard';
import MSMEImpact from './MSME Impact/MsmeImpactDashboard';
import GoogleLogin from './LoginCredential/GoogleLogin'; // Import your custom GoogleLogin component

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  // Google OAuth success handler
  const handleGoogleSuccess = (response) => {
    console.log('Login successful:', response);
    setIsLoggedIn(true);
    setUserDetails(response.profileObj); // Optionally store user profile data
    localStorage.setItem('isLoggedIn', 'true');
  };

  // Google OAuth failure handler
  const handleGoogleFailure = (error) => {
    console.error('Login failed:', error);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserDetails(null);
    localStorage.removeItem('isLoggedIn'); // Clear login state
    sessionStorage.clear(); // Clear session data if needed
  };

  useEffect(() => {
    const storedLogin = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(storedLogin === 'true');
  }, []); // Runs once on component mount

  return (
    <GoogleOAuthProvider clientId="555335856204-5pnk4cv225loruelbffkhjft659te6dq.apps.googleusercontent.com"> {/* Replace with your actual Google Client ID */}
      <Router>
        <div className="app">
          {!isLoggedIn ? (
            <GoogleLogin
              onLoginSuccess={handleGoogleSuccess}   // Pass success handler
              onLoginFailure={handleGoogleFailure}   // Pass failure handler
            />
          ) : (
            <>
              <Header handleLogout={handleLogout} /> {/* Pass handleLogout to Header */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Cards />
                      <Dashboard />
                    </>
                  }
                />
                <Route path="/historical" element={<Historical />} />
                <Route path="/hd-impact" element={<HDImpact />} />
                <Route path="/citizen-impact" element={<CitizenImpact />} />
                <Route path="/map2" element={<Map2 />} />
                <Route path="/clientfilter" element={<ClientFilter />} />
                <Route path="/allfilters" element={<AllFilters />} />
                <Route path="/msme-impact" element={<MSMEImpact />} />
              </Routes>
            </>
          )}
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
