import React, { useState, useEffect } from 'react';
import MsmeImpactCards from './MsmeImpactCards';
import './MsmeImpactDashboard.css';
import MsmeImpactSidebar from './MsmeImpactSidebar';
import MsmeImpactMap from './MsmeImpactMap';
import MsmeImpactRightSidebar from './MsmeImpactRightSidebar';
import { MsmeImpactButtonContainer } from './MsmeImpactButton';
import MsmeImpactTable from './MsmeImpactTable';

const MsmeImpactDashboard = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAgeRange, setSelectedAgeRange] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null); // State to store the last update timestamp

  useEffect(() => {
    // Fetch the date and time from the date&time.json file in the public folder
    const fetchLastUpdated = async () => {
      try {
        const response = await fetch('/date&time.json');
        const data = await response.json();
        setLastUpdated(new Date(data.updatedAt).toLocaleString()); // Convert ISO format to readable string
      } catch (error) {
        console.error('Error fetching last updated time:', error);
      }
    };

    fetchLastUpdated(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="msmeimpact-main-content">
      <MsmeImpactCards />
      <div className="msmeimpact-dashboard">
        <MsmeImpactSidebar 
          onGenderChange={setSelectedGender} 
          onAgeRangeChange={setSelectedAgeRange} 
          onProjectNameChange={setSelectedProjectName}
        />
        <div className="msmeimpact-map-container">
          <MsmeImpactButtonContainer />
          <MsmeImpactMap 
            selectedGender={selectedGender} 
            selectedAgeRange={selectedAgeRange} 
            selectedProjectName={selectedProjectName} 
          />
        </div>
        <div className="msmeimpact-rightsidebar-container">
          
          {/* Display the last updated timestamp below the right sidebar */}
          {lastUpdated && (
            <div className="last-updated">
              <p>Last updated: {lastUpdated}</p>
            </div>
          )}
          <MsmeImpactRightSidebar />
        </div>
      </div>
      <MsmeImpactTable 
        selectedGender={selectedGender} 
        selectedAgeRange={selectedAgeRange} 
      />
    </div>
  );
};

export default MsmeImpactDashboard;
