// src/components/MsmeImpactDropdown.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './MsmeImpactDropdown.css';

const MsmeImpactDropdown = ({ label = "Select an option", options = [], onSelect = () => {}, header = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="msme-impact-dropdown-container">
      {header && <div className="msme-impact-dropdown-header">{header}</div>}
      <div className="msme-impact-dropdown" ref={dropdownRef} role="listbox">
        <div
          className="msme-impact-dropdown-label"
          onClick={handleToggle}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
        >
          {selectedOption || label}
          <span className={`msme-impact-dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
        </div>
        {isOpen && (
          <div className="msme-impact-dropdown-menu" role="listbox">
            {options.map((option, index) => (
              <div
                key={index}
                className="msme-impact-dropdown-item"
                role="option"
                onClick={() => handleOptionClick(option)}
                tabIndex={0}
                aria-selected={selectedOption === option}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

MsmeImpactDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  header: PropTypes.string,
};

export default MsmeImpactDropdown;
