import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientSidebar.css';
import Dropdown from './ClientDropdown';

const ClientSidebar = ({ setFilters, setHeatmapData, onSchemesChange }) => { 
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    client: '',
    project: '',
    projectType: '',
    heatmapType: ''
  });

  useEffect(() => {
    fetch('/project_data.json')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
        updateFilters(data);
        if (setHeatmapData) setHeatmapData(data); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const updateFilters = (data) => {
    const projectNames = data.map(item => item.PROJECT_ID);
    const clientNames = data.map(item => item.Project_Name);
    const projectTypeNames = data.map(item => item.Project_Type);

    setProjects([...new Set(projectNames)]);
    setClients([...new Set(clientNames)]);
    setProjectTypes([...new Set(projectTypeNames)]);
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: value
    }));
    setFilters(filterKey, value);

    let newFilteredData = data;

    if (filterKey === 'heatmapType') {
      newFilteredData = data.filter(item => {
        switch (value) {
          case 'Application Open':
            return item.OpenCount > 0;
          case 'Benefit Received':
            return parseInt(item.BV, 10) > 0;
          case 'Applications Submitted':
            return item.submitted > 0;
          case 'Benefits Unlocked':
            return item.BR > 0;
          default:
            return true;
        }
      });

      setFilteredData(newFilteredData);
      updateFilters(newFilteredData);
      if (setHeatmapData) setHeatmapData(newFilteredData);
    } else {
      Object.keys(selectedFilters).forEach(key => {
        if (key !== filterKey && selectedFilters[key]) {
          newFilteredData = newFilteredData.filter(item => {
            switch (key) {
              case 'client':
                return item.Project_Name === selectedFilters[key];
              case 'project':
                return item.PROJECT_ID === selectedFilters[key];
              case 'projectType':
                return item.Project_Type === selectedFilters[key];
              default:
                return true;
            }
          });
        }
      });

      newFilteredData = newFilteredData.filter(item => {
        switch (filterKey) {
          case 'client':
            return item.Project_Name === value;
          case 'project':
            return item.PROJECT_ID === value;
          case 'projectType':
            return item.Project_Type === value;
          default:
            return true;
        }
      });

      setFilteredData(newFilteredData);
      updateFilters(newFilteredData);
      if (setHeatmapData) setHeatmapData(newFilteredData);
    }

    if (filterKey === 'client') {
      const topSchemes = newFilteredData
        .filter(item => item.Project_Name === value)
        .sort((a, b) => b.submitted - a.submitted) // Example sorting logic
        .slice(0, 5); // Get top 5 schemes

      if (onSchemesChange) onSchemesChange(topSchemes);
    }
  };

  const handleClearFilter = (filterKey) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterKey]: ''
    }));
    setFilters(filterKey, '');

    const newFilteredData = data.filter(item => {
      return Object.keys(selectedFilters).every(key => {
        if (key === filterKey) return true;
        if (!selectedFilters[key]) return true;
        switch (key) {
          case 'client':
            return item.Project_Name === selectedFilters[key];
          case 'project':
            return item.PROJECT_ID === selectedFilters[key];
          case 'projectType':
            return item.Project_Type === selectedFilters[key];
          default:
            return true;
        }
      });
    });

    setFilteredData(newFilteredData);
    updateFilters(newFilteredData);
    if (setHeatmapData) setHeatmapData(newFilteredData);
  };

  const filterOptions = {
    client: clients,
    project: projects,
    projectType: projectTypes
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <aside className="client-sidebar">
      <nav>
        <h2 className="client-sidebar-header">Filter Data By</h2>
        <ul className="client-sidebar-list">
          {Object.keys(selectedFilters).map((filterKey) => (
            filterKey !== 'heatmapType' && (
              <li className="client-sidebar-item" key={filterKey}>
                <div className="dropdown-container">
                  <Dropdown
                    label="Select"
                    options={filterOptions[filterKey] || []}
                    header={capitalizeFirstLetter(filterKey)}
                    selectedOption={selectedFilters[filterKey]}
                    onSelect={(value) => handleFilterChange(filterKey, value)}
                  />
                  {selectedFilters[filterKey] && (
                    <button
                      className="clear-filter-btn" 
                      onClick={() => handleClearFilter(filterKey)}
                    >
                      &#10005;
                    </button>
                  )}
                </div>
              </li>
            )
          ))}
        </ul>
        <button 
          className="remove-filters-button" 
          onClick={() => navigate(-1)} 
        >
          Back To Home
        </button>
      </nav>
    </aside>
  );
};

export default ClientSidebar;
