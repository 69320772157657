import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './HDImpactDropdown.css';

const HDImpactDropdown = ({ label = "Select an option", options = [], onChange = () => {}, header = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  // Toggle dropdown open/close state
  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  // Handle option selection
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);  // Call onChange with the selected option
  };

  // Clear the selected option
  const handleClearSelection = () => {
    setSelectedOption(null);
    onChange(null);  // Clear the selection in the parent component
  };

  // Close dropdown on pressing Escape key
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  // Attach/detach event listeners for clicking outside and pressing Escape
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClickOutside]);

  return (
    <div className="hd-impact-dropdown-container">
      {header && <div className="hd-impact-dropdown-header">{header}</div>}
      <div className="hd-impact-dropdown" ref={dropdownRef} role="listbox">
        <div
          className="hd-impact-dropdown-label"
          onClick={selectedOption ? null : handleToggle} // Disable toggle if an option is selected
          aria-expanded={isOpen}
          aria-haspopup="listbox"
        >
          {selectedOption || label}
          {selectedOption ? (
            <span className="hd-impact-dropdown-clear" onClick={handleClearSelection}>&#x2715;</span>  // Clear button (X)
          ) : (
            <span className={`hd-impact-dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>  // Dropdown arrow
          )}
        </div>
        {isOpen && !selectedOption && (
          <div className="hd-impact-dropdown-menu" role="listbox">
            {options.length === 0 ? (
              <div className="hd-impact-dropdown-item">No options available</div>
            ) : (
              options.map((option, index) => (
                <div
                  key={index}
                  className="hd-impact-dropdown-item"
                  role="option"
                  onClick={() => handleOptionClick(option)}
                  tabIndex={0}
                  aria-selected={selectedOption === option}
                >
                  {option}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

HDImpactDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  header: PropTypes.string,
};

export default HDImpactDropdown;
